<template>
  <div class="container-fluid">
    <b-alert v-if="showAlert" variant="danger" show>
      <p v-for="(message, i) in leadersByRegionMessages" :key="i">{{ message }}</p>
    </b-alert>
    <TableWithPagination ref="users" :items="users" :fields="usersFields" :totalRows="usersTotal" @change-params="onUsersChangeParams" @row-selected="onUsersRowSelected">
      <template v-slot:filters="{ filters, changeFilter }">
        <div class="row">
          <div class="col">
            <b-form-select v-model="filters.regionId" :options="regionsDD" @change="changeFilter('regionId', $event)">
              <template #first>
                <b-form-select-option :value="undefined">Регион</b-form-select-option>
              </template>
            </b-form-select>
          </div>
          <div class="col">
            <b-form-select v-model="filters.roleId" :options="rolesDD" @change="changeFilter('roleId', $event)">
              <template #first>
                <b-form-select-option :value="undefined">Роль</b-form-select-option>
              </template>
            </b-form-select>
          </div>
          <div class="col">
            <b-form-input v-model="filters.search" placeholder="Поиск" type="search" @input="changeFilter('search', $event)"></b-form-input>
          </div>
        </div>
      </template>
      <template v-slot:filters-actions>
        <b-button variant="success" @click="onAddUser">Добавить пользователя</b-button>
      </template>
      <template v-slot:row-actions>
        <b-button class="mr-2 btn-danger" v-b-modal.modalBlockConfirmation v-if="!isCurrentUser && !isLocked">Заблокировать</b-button>
        <b-button class="mr-2 btn-success" @click="onUnBlockClick" v-if="!isCurrentUser && isLocked">Разблокировать</b-button>
        <b-button class="mr-2 btn-success" v-b-modal.modalResetPassword v-if="!isCurrentUser">Сбросить пароль</b-button>
        <b-button class="btn-success" v-if="haveEditMode" @click="onEditUser">Редактировать</b-button>
        <span class="ml-3 spinner" v-if="loading">
          <b-spinner variant="success" label="Spinning"></b-spinner>
        </span>
        <div class="text-danger">{{ message }}</div>
      </template>
    </TableWithPagination>

    <RightSidebar
      form="UserForm"
      :form-data="userFormData"
      :mode="userFormMode"
      :show="userFormShow"
      @update:show="userFormShow = $event;userFormData = {}"
      @saved:form-data="onSavedUserForm"
    ></RightSidebar>

    <b-modal id="modalBlockConfirmation" title="Блокировать пользователя" ok-title="Подтвердить" cancel-title="Отмена" centered @ok="onBlockUser">
      Подтвердите блокировку пользователя
    </b-modal>

    <b-modal ref="modalUnBlockConfirmation" title="Разблокировать пользователя" ok-title="Подтвердить" cancel-title="Отмена" centered @ok="onUnblockUser">
      Подтвердите разблокирование пользователя
    </b-modal>

    <b-modal id="modalResetPassword" title="Сброс пароля" ok-title="Подтвердить" cancel-title="Отмена" centered @ok="onResetPassword">
      Подтвердите сброс пароля пользователя
    </b-modal>

  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters, mapState } from "vuex";
import { Role, RoleId } from "@/constants";
import { TableWithPagination, RightSidebar } from "@/components";

export default {
  components: {
    TableWithPagination,
    RightSidebar
  },
  name: "Users",
  data() {
    return {
      loading: false,

      filters: {
        regionId: null,
        roleId: null,
        search: ""
      },
      usersFields: [
        { key: "firstName", label: "Имя", sortable: true },
        { key: "lastName", label: "Фамилия", sortable: true },
        { key: "region", label: "Регион", sortable: true },
        { key: "role", label: "Роль", sortable: true, formatter: item => Role.translate(item) },
        { key: "email", label: "Email", sortable: true },
        { key: "agreeWithTheAgreement", label: "Согласие с ПОПД", sortable: true, formatter: item => (item ? "Да" : "Нет") },
        { key: "isLocked", label: "Заблокирован", sortable: true, formatter: item => (item ? "Да" : "Нет") },
        { key: "isApproved", label: "Подтвержден", sortable: true, formatter: item => (item ? "Да" : "Нет") }
      ],
      selectedUser: null,
      userFormData: {},
      userFormMode: "ADD",
      userFormShow: false
    };
  },
  methods: {
    ...mapActions("users", ["fetchRoles", "fetchUsers", "checkLeadersByRegion", "blockUser", "unblockUser", "resetPassword", "unblockCheck"]),
    ...mapActions("regions", ["fetchRegions"]),

    onUsersChangeParams(params) {
      this.fetchUsers(params);
    },
    onUsersRowSelected(user) {
      this.selectedUser = user;
    },

    async onBlockUser() {
      this.loading = true;
      this.blockUser(this.selectedUser.id).then(() => {
        this.loading = false;
        this.checkLeadersByRegion();
        this.$refs.users.reload();
      });
    },
    onUnBlockClick() {
      if (this.selectedUser.role === Role.Leader) {
        this.unblockCheck(this.selectedUser.id).then((res) => {
          if (!res.ok) return
          this.$refs["modalUnBlockConfirmation"].show()
        })
      }
      else {
        this.$refs["modalUnBlockConfirmation"].show()
      }
    },
    async onUnblockUser() {
      this.loading = true;
      this.unblockUser(this.selectedUser.id).then(() => {
        this.loading = false;
        this.checkLeadersByRegion();
        this.$refs.users.reload();
      });
    },
    async onResetPassword() {
      this.loading = true;
      this.resetPassword(this.selectedUser.id).then(() => {
        this.loading = false;
      });
    },
    onAddUser() {
      this.userFormMode = "ADD"
      this.userFormShow = true
    },
    onEditUser() {
      this.userFormData = { ...this.selectedUser };
      this.userFormData.roleId = RoleId[this.selectedUser.role];
      this.userFormMode = "EDIT"
      this.userFormShow = true
    },
    onSavedUserForm() {
      this.checkLeadersByRegion();
      this.$refs.users.reload();
    }    
  },
  computed: {
    ...mapState({
      currentuser: state => state.auth.user,
      users: state => state.users.users,
      usersTotal: state => state.users.usersTotal,
      message: state => state.users.message,
      leadersByRegionMessages: state => state.users.leadersByRegionMessages
    }),
    ...mapGetters("users", ["rolesDD", "editRolesDD"]),
    ...mapGetters("regions", ["regionsDD"]),

    isCurrentUser() {
      return this.selectedUser && this.selectedUser.id === this.currentuser.id;
    },
    haveEditMode() {
      return !this.isCurrentUser && this.selectedUser &&  this.selectedUser.role !== Role.Manufacturer && this.selectedUser.role !== Role.Dealer;
    },
    isLocked() {
      if (this.selectedUser && this.selectedUser.isLocked) return true;
      else return false;
    },
    showAlert() {
      return this.leadersByRegionMessages && this.leadersByRegionMessages.length > 0;
    }
  },
  created() {
    this.fetchRegions();
    this.fetchRoles();
    this.checkLeadersByRegion();
  }
};
</script>

<style lang="scss" scoped>
.b-table-sticky-header > .table.b-table > thead > tr > th {
  top: -1px !important;
}
p {
  margin-bottom: 0;
}
</style>
